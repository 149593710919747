import { ApiRequest, Benutzer, Benutzerprofil, Besichtigung, Mandant, PaginierteListe, Vorgang, ZahlungseingangHistorie, Zahlungsimport } from '../types';

export const BENUTZERPROFIL = (username: string) => `/benutzerprofil/${username}`;

export const BESICHTIGUNG = (id: string) => `/besichtigung/${id}`;

export const VORGANG_PROTOKOLL = (vorgangId: string) => `/protokoll/${vorgangId}`;

export const VORGANG_ANLEGEN = '/vorgang/anlegen';

export const MANDANT = (mandantId: string) => `/mandant/${mandantId}`;

export const ZAHLUNGSEINGANG_IMPORT = '/zahlungseingang/import';
export const ZAHLUNGSEINGANG_HISTORIE = '/zahlungseingang/historie';
export const ZAHLUNGSEINGANG_HISTORIE_LOESCHEN = '/zahlungseingang/historie/loeschen';
export const ZAHLUNGSEINGANG_LOESCHEN = '/zahlungseingang/loeschen';

export const benutzerprofil = {
  get: (username: string): ApiRequest<Benutzer & Benutzerprofil> => {
    return {
      url: protectedPath(BENUTZERPROFIL(username)),
      method: 'GET'
    };
  },
  patch: (username: string, benutzerprofil: Partial<Benutzer>): ApiRequest<Benutzer & Benutzerprofil> => {
    return {
      url: protectedPath(BENUTZERPROFIL(username)),
      method: 'PATCH',
      body: benutzerprofil
    };
  }
};

export const besichtigung = {
  post: (id: string | undefined, partial: Partial<Besichtigung>): ApiRequest<Besichtigung> => {
    return {
      url: protectedPath(BESICHTIGUNG(id ?? '')),
      method: 'POST',
      body: partial
    };
  },
  delete: (id: string): ApiRequest<Besichtigung> => {
    return {
      url: protectedPath(BESICHTIGUNG(id ?? '')),
      method: 'DELETE'
    };
  }
};

export const zahlungseingang = {
  importPostUrl: (body: string): ApiRequest<Zahlungsimport> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_IMPORT),
      method: 'POST',
      headers: {
        'content-type': 'text/csv'
      },
      body
    };
  },
  getUrl: (jahr: number, nextToken?: string | null): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(`${ZAHLUNGSEINGANG_HISTORIE}?jahr=${jahr}&nextToken=${nextToken ?? ''}`),
      method: 'GET'
    };
  },
  historieLoeschenPostUrl: (jahr: string, importDatum: string): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_HISTORIE_LOESCHEN),
      method: 'POST',
      body: {
        jahr,
        importDatum
      }
    };
  },
  loeschePostUrl: (id: string, jahr: string, importDatum: string): ApiRequest<PaginierteListe<ZahlungseingangHistorie>> => {
    return {
      url: protectedPath(ZAHLUNGSEINGANG_LOESCHEN),
      method: 'POST',
      body: {
        id,
        jahr,
        importDatum
      }
    };
  }
};

export const vorgang = {
  legeVorgangAn: (mandant: string): ApiRequest<Vorgang> => {
    return {
      url: protectedPath(VORGANG_ANLEGEN),
      method: 'POST',
      body: {
        mandant
      }
    };
  }
};

export const events = {
  get: (vorgangId: string, nextToken?: string | null): ApiRequest<PaginierteListe<Event>> => {
    const queryParams = nextToken ? '?nextToken=' + nextToken : '';
    return {
      url: protectedPath(VORGANG_PROTOKOLL(vorgangId)) + queryParams,
      method: 'GET'
    };
  }
};

export const mandantOperations = {
  get: (mandantId: string): ApiRequest<Mandant> => {
    return {
      url: protectedPath(MANDANT(mandantId)),
      method: 'GET'
    };
  },
  post: (mandantId: string | undefined, body: Partial<Mandant>): ApiRequest<Mandant> => {
    return {
      url: protectedPath(MANDANT(mandantId ?? '')),
      method: 'POST',
      body
    };
  }
};

function protectedPath(path: string) {
  return `protected${path}`;
}
