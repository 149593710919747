import React, { useCallback, useEffect, useState } from 'react';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from '../../components/common/Alert';
import { getMessageFromError } from '../../shared/throw';
import { api } from '../../apigateway';
import Paper from '@mui/material/Paper';
import { PaginierteListe, ZahlungseingangHistorie } from '../../types';
import { aktuellesJahr, toDateTimeString } from '../../shared/dateTime';
import Tabelle from '../../components/Tabelle/Tabelle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { zahlungseingang } from '../../shared/url';
import Bestaetigungsdialog from '../../components/dialog/Bestaetigungsdialog';

const startJahr = 2024;
const jahrAktuell = aktuellesJahr();
const jahre: number[] = [];

for (let i = startJahr; i <= jahrAktuell; ++i) {
  jahre.push(i);
}

const leer = { items: [], nextTokenEncoded: null };

type Props = {
  readonly isLoading: boolean;
  readonly onClickRow?: (row: ZahlungseingangHistorie) => void;
};

export function ZahlungshistorieTabelle({ isLoading, onClickRow }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingHistorie, setIsLoadingHistorie] = useState(false);
  const [importZumLoeschen, setImportZumLoeschen] = useState<ZahlungseingangHistorie | null>();
  const [ausgewaehltesJahr, setAusgewaehltesJahr] = useState(jahrAktuell);
  const [data, setData] = useState<PaginierteListe<ZahlungseingangHistorie>>(leer);

  const fetchData = useCallback(
    (jahr: number, nextToken?: string | null) => {
      setIsLoadingHistorie(true);

      api
        .request(zahlungseingang.getUrl(jahr, nextToken))
        .then((antwort) => {
          setData((current) => ({
            items: [...current.items, ...antwort.items],
            nextTokenEncoded: antwort.nextTokenEncoded
          }));
        })
        .catch((error) => enqueueSnackbar(`Die Zahlungshistorie konnte nicht geholt werden: ${getMessageFromError(error.data)}`, ERROR_MESSAGE))
        .finally(() => setIsLoadingHistorie(false));
    },
    [enqueueSnackbar]
  );

  const loescheHistorie = useCallback(
    (zeile: ZahlungseingangHistorie) => {
      setIsLoadingHistorie(true);

      api
        .request(zahlungseingang.historieLoeschenPostUrl(zeile.id!, zeile.importDatum))
        .then(() => api.request(zahlungseingang.getUrl(ausgewaehltesJahr)))
        .then((antwort) => {
          setData({
            items: antwort.items,
            nextTokenEncoded: antwort.nextTokenEncoded
          });
        })
        .catch((error) => enqueueSnackbar(`Die Zahlungshistorie konnte nicht zurückgesetzt werden: ${getMessageFromError(error.data)}`, ERROR_MESSAGE))
        .finally(() => setIsLoadingHistorie(false));
    },
    [ausgewaehltesJahr, enqueueSnackbar]
  );

  useEffect(() => {
    fetchData(ausgewaehltesJahr);
  }, [fetchData, ausgewaehltesJahr]);

  const jahrAuswahl = (
    <Box sx={{ position: 'relative', left: '0px' }} className="foo">
      <FormControl variant="standard">
        <InputLabel>Jahr</InputLabel>
        <Select
          variant="standard"
          disabled={isLoading || isLoadingHistorie}
          value={ausgewaehltesJahr}
          onChange={(event) => {
            setData(leer);
            setAusgewaehltesJahr(Number(event.target.value));
          }}
        >
          {jahre.map((typ, index) => {
            return (
              <MenuItem key={index} value={typ}>
                {typ}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );

  return (
    <>
      <Bestaetigungsdialog
        title="Import löschen"
        text="Möchten Sie den Import wirklich löschen?"
        open={!!importZumLoeschen}
        onClose={() => setImportZumLoeschen(null)}
        onDelete={() => {
          loescheHistorie(importZumLoeschen!);
          setImportZumLoeschen(null);
        }}
      />

      <Paper
        sx={(theme) => ({
          padding: theme.spacing(SPACING_BETWEEN_BOXES)
        })}
      >
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <Tabelle
              actions={[
                {
                  label: 'Zurücksetzen',
                  onClick: setImportZumLoeschen,
                  disabled: isLoading
                }
              ]}
              columns={[
                { label: 'Importdatum', id: 'importDatum', render: (zeile) => toDateTimeString(zeile.importDatum) },
                { label: 'Ersteller', id: 'ersteller' }
              ]}
              tableData={data.items}
              disabled={isLoading || isLoadingHistorie}
              typAuswahl={jahrAuswahl}
              title="Importhistorie"
              onClickRow={onClickRow}
              isLoading={isLoadingHistorie} /* Absicht: ohne isLoading */
            />
            {data.nextTokenEncoded && (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isLoading || isLoadingHistorie}
                  onClick={() => fetchData(ausgewaehltesJahr, data.nextTokenEncoded)}
                >
                  Lade weitere...
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
